import buddhabrothersabi from "./abis/buddhabrothers.json";
import temple from "./abis/temple.json";
import token from "./abis/token.json";

export const buddhabrothersAbi = buddhabrothersabi;
export const templeAbi = temple;
export const tokenAbi = token;
export const buddhabrothersAddress = "0x6669B585199937A4158BaE49D83B57507F866144";
export const templeAddress = '0xabcd517c9984fe291012a3302b56ffd730e84bc0';
export const tokenAddress = '0x7d19946b83bd179adefcd14d491a5592f50c6836';

export const moralisApiKey = "TYaN52ptWaqOTRlmao9e2vg2LXO3OciEDOdkshakvkxBwvC6Y9WQfixyv1LmxxaI";
