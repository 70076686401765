import '../css/game.css';
import Timer from './Timer';
import PregameLobby from './PregameLobby';
import EventRegistration from "./EventRegistration";
import { GameContext } from "../contexts/GameContext";
import { useContext, useEffect, useState } from 'react';
import { STAY_LEAVE_DECISION_SECONDS, ROUND_SECONDS } from '../utils/constants';


const Game = () => {
    const [waitingForGame, setWaitingForGame] = useState(false);
    const [stayDecision, setStayDecision] = useState(true);

    // context from GameContext Provider
    const {
        currentAccount,
        // accountNfts,
        // isAccountLoggedIn,
        // setIsAccountLoggedIn,

        cookies,

        lobbyDetails,
        eventDetails,
        wsState,

        gameState,
        setGameState,

        accountRegistered
    } = useContext(GameContext);

    useEffect(() => {
        isGameStarted();

        // console.log("FROM USEEFFECT", gameState, eventDetails);

        if (gameState.winner.includes(currentAccount)) {
            userWon();
        }

        if (gameState.losers.includes(currentAccount)) {
            userLost();
        }

    }, [gameState]);

    const isGameStarted = () => {
        const currentUnixTimeStamp = Math.round(new Date() / 1000);
        const startAt = eventDetails.event_start;
        const completed = eventDetails.completed;

        if (eventDetails.started === false) {
            if (startAt > currentUnixTimeStamp && !completed) {
                setWaitingForGame(true);
            }
        }        
    }

    // Game decisions and other functionality
    const userWon = () => {
        document.getElementsByClassName('winningContainer')[0].style.display = 'flex';
        document.getElementsByClassName('decisionsContainer')[0].style.display = 'none';
        document.getElementsByClassName('statsContainer')[0].style.display = 'none';
    }

    const userLost = () => {
        document.getElementsByClassName('loosingContainer')[0].style.display = 'flex';
    }

    const makeDecision = async (e) => {
        var decision = e.target.value;
        wsState.send(JSON.stringify({
            'jwt': cookies.jwt,
            'decision': decision,
            'type': 'make_decision',
            'address': currentAccount
        }));

        setGameState({...gameState, decisionMade: true, decision: decision});
        document.getElementsByClassName('decisionTimerMsg')[0].innerHTML = 'Waiting for the round to end';
        document.getElementsByClassName('decisionBtnsContainer')[0].style.display = 'none';
        document.getElementsByClassName('afterDecision')[0].style.display = 'block';
    }

    const stayOrLeave = (e) => {
        var decision = e.target.value;
        wsState.send(JSON.stringify({
            'jwt': cookies.jwt,
            'decision': decision,
            'type': 'stay_or_leave',
            'address': currentAccount
        }));

        document.getElementsByClassName('slDecisionBtnsContainer')[0].style.display = 'none';
        document.getElementsByClassName('afterSLDecision')[0].style.display = 'block';

        if (decision === 'leave') {
            setStayDecision(false);
        } else {
            setStayDecision(true);
        }
    }

    return (
        <div>
            {
                // check if in pregame lobby
                lobbyDetails.joiningPeriod ?
                        accountRegistered ?
                            <PregameLobby />
                        : <div className='notInGame'>Sorry, you didn't register for the event</div>
                    :
                    // the actual game
                    eventDetails.started && gameState.remaining_accounts.includes(currentAccount) && accountRegistered ?
                        !gameState.round_started && !gameState.winner.includes(currentAccount) ?
                            <div className='stayOrLeaveContainer'>
                                <div className='stayOrLeaveStats'>
                                    <Timer time={STAY_LEAVE_DECISION_SECONDS} type={'simple'}/>
                                    <span className='winningStats'>Current Winnings: {gameState.account_gains[currentAccount]} <img className='tokenImg' alt="" src={window.location.origin + '/images/buddha_logo.png'}/> Tokens!</span>
                                </div>
                                <div className='slDecisionBtnsContainer'>
                                    <button className="slDecisionBtn stayDecisionBtn" onClick={stayOrLeave} value="stay">Continue</button>
                                        <span>OR</span>
                                    <button className="slDecisionBtn leaveDecisionBtn" onClick={stayOrLeave} value="leave">Leave</button>
                                </div>

                                <span className='afterSLDecision'>You decided to 
                                    <span className={stayDecision === true ? 'afterSLDecisionStay' : 'afterSLDecisionLeave'}>{stayDecision ? 'stay' : 'leave'}</span>
                                </span>
                            </div>
                        :
                        eventDetails.started && gameState.remaining_accounts.includes(currentAccount) && gameState.round_accounts[gameState.total_rounds+1].includes(currentAccount) && !gameState.winner.includes(currentAccount) ?
                            <div className='toNextRoundContainer'>
                                <span>You have have been promoted to the next round!</span>
                                <span className='nextRoundBalance'>Balance: {gameState.account_gains[currentAccount]} <img className='tokenImg' alt="" src={window.location.origin + '/images/buddha_logo.png'}/> Tokens!</span>
                            </div>
                        :
                        <section className="gameContainer">
                            <div className="statsContainer">
                                <div>
                                    <span>Total Players</span>
                                    <span>{gameState.remaining_accounts.length}</span>
                                </div>
                                <div>
                                    <span>Tokens in Game</span>
                                    <div>
                                        <span>{gameState.running_pot}</span>
                                        <img className='tokenImg' alt="" src={window.location.origin + '/images/buddha_logo.png'}/>
                                    </div>
                                </div>
                                {/* <div>
                                    <span>Amount in Jackpot</span>
                                    <span>{gameState.jackpot}</span>
                                </div> */}
                            </div>

                            <div className='winningContainer'>
                                <span>You Won! - Your opponent decided to split</span>
                                <span className='winningBalance'>Winnings: {gameState.account_gains[currentAccount]} <img className='tokenImg' alt="" src={window.location.origin + '/images/buddha_logo.png'}/> Tokens!</span>
                            </div>

                            <div className="decisionsContainer">
                                <div className="decisionTimerContainer">
                                    <Timer time={Math.round((gameState.round_start_time + ROUND_SECONDS) - (new Date() / 1000))} type={'simple'}/>
                                    <span className='decisionTimerMsg'>Make your decision!</span>
                                </div>

                                <div className="decisionBtnsContainer">
                                    <button className="decisionBtn splitDecisionBtn" onClick={makeDecision} value="split">Split</button>
                                    <span>OR</span>
                                    <button className="decisionBtn stealDecisionBtn" onClick={makeDecision} value="steal">Steal</button>
                                </div>

                                <span className='afterDecision'>You decided to 
                                    <span className={gameState.decision === 'split' ? 'afterDecisionSplit' : 'afterDecisionSteal'}>{gameState.decision}</span>
                                </span>

                            </div>
                        </section>
                    :
                    !stayDecision && !gameState.remaining_accounts.includes(currentAccount) && !gameState.losers.includes(currentAccount) ?
                        <div className='leaveContainer'>
                            <span>You Left!</span>
                            <span className='leaveBalance'>Winnings: {gameState.account_gains[currentAccount]} <img className='tokenImg' alt="" src={window.location.origin + '/images/buddha_logo.png'}/> Tokens!</span>
                        </div>
                    :
                    gameState.account_gains[currentAccount] > 0 && !gameState.remaining_accounts.includes(currentAccount) && !gameState.losers.includes(currentAccount)
                    ?
                        <div className='leaveContainer'>
                            <span>You failed to decide!</span>
                            <span className='leaveBalance'>Balance: {gameState.account_gains[currentAccount]} <img className='tokenImg' alt="" src={window.location.origin + '/images/buddha_logo.png'}/> Tokens!</span>
                        </div>
                    :
                    gameState.losers.includes(currentAccount) ?
                        <div className='loosingContainer'>
                            <span>You Lost! - Your opponent decided to steal</span>
                            <span>Better luck next time!</span>
                        </div>
                    :
                        !waitingForGame ?
                            <div className='notInGame'>A game is currently on going! Please revisit at a later time!</div>
                        :
                        <EventRegistration />
            }
        </div>
    );
}

export default Game;
