import axios from 'axios';
import { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { GameContext } from '../contexts/GameContext';
import '../css/registration.css';
import { backendBaseUrl } from '../utils/constants';
import Timer from './Timer';

const Registration = () => {
    const [failMsg, setFailMsg] = useState('');

    const {
        currentAccount,

        eventDetails,
        setEventDetails,

        accountRegistered,
        setAccountRegistered,

        cookies
    } = useContext(GameContext);

    const handleRegistrationSubmit = (e) => {
        e.preventDefault();

        const registrationUrl = backendBaseUrl + 'registerforevent/';

        const registrationReqBody = new FormData();
        registrationReqBody.append('jwt', cookies.jwt);
        registrationReqBody.append('event_id', eventDetails.event_id);
        registrationReqBody.append('account_address', currentAccount);

        axios({
            method: "post",
            url: registrationUrl,
            data: registrationReqBody,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(resp => {
            setAccountRegistered(true);
        })
        .catch(err => {
            console.log(err);
        })
    }

    return ( 
        <div className="registrationContainer">
            <span className='eventHeading'>The event will start in</span>
            <Timer time={eventDetails.event_start}/>

            {
                accountRegistered ?
                    <div></div>
                :
                    <div>
                        <span className="failureMsg">{failMsg}</span>
                        <form onSubmit={handleRegistrationSubmit}>
                            <button className="registerBtn">Register Now!</button>
                        </form>
                    </div>
            }
        </div>
     );
}
 
export default Registration;