import '../css/lobby.css';

import { useContext } from 'react';
import { GameContext } from '../contexts/GameContext';
import Timer from './Timer';

const PregameLobby = () => {
    const {
        lobbyJoined,
        lobbyDetails,
        // setLobbyJoined,
        // wsState,

        // currentAccount
    } = useContext(GameContext);

    // GAME FUNCTIONLAITY
    // const handleJoinLobby = async () => {
    //     setLobbyJoined(true);
    //     wsState.send(JSON.stringify({
    //         'type': 'join_lobby',
    //         'address': currentAccount
    //     }));
    // }

    return (
        <section className="LobbyContainer">
            {/* <span className='lobbyPlayersCount'>Players in lobby: {lobbyDetails.totalPlayers}</span> */}
            <span className='lobbyTimer'>
                Game will start in
                <Timer time={lobbyDetails.lobbyTimer} type={'simple'}/>
            </span>
            {
                lobbyJoined ?
                    <span className='joinedText'>Lobby Joined!</span>
                :
                <span></span>
                // <button className='joinLobbyBtn' onClick={handleJoinLobby}>Join Lobby</button>
            }
            
        </section>
     );
}
 
export default PregameLobby;