import axios from "axios";
import { ethers } from 'ethers';
import {
    buddhabrothersAbi,
    buddhabrothersAddress,
    tokenAbi,
    tokenAddress,
    templeAbi,
    templeAddress,
    moralisApiKey
} from '../utils/contracts';
import { useCookies } from 'react-cookie';
import React, { useEffect, useState } from "react";
import { authBackendBaseUrl, backendBaseUrl, wsBackendBaseUrl } from '../utils/constants';


// get ethereum object
const { ethereum } = window;

const getbuddhaBrothersContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    
    const buddhaBrothersContract = new ethers.Contract(buddhabrothersAddress, buddhabrothersAbi, signer);
    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const templeContract = new ethers.Contract(templeAddress, templeAbi, signer);
    
    return {buddhabrothers: buddhaBrothersContract,
    	    token: tokenContract,
    	    temple: templeContract};
}

export const GameProvider = ({ children }) => {
    useEffect(() => {
        // setLoader(true);
        checkIfWalletIsConnected();
        // setLoader(false);
    }, []);

    ////////////////////////////////////
    //          General State         //
    ////////////////////////////////////
    // loader state
    // const setLoader = (state) => {
    //     if (state) {
    //         document.getElementsByClassName('loader')[0].style.display = 'flex';
    //     } else {
    //         document.getElementsByClassName('loader')[0].style.display = 'none';
    //     }
    // }

    ////////////////////////////////////
    //           Web3 State           //
    ////////////////////////////////////
    const [accountNfts, setAccountNfts] = useState(0);
    const [currentAccount, setCurrentAccount] = useState(undefined);
    const [tokenBalance, setTokenBalance] = useState(0);
    
    // logged in states
    const [isAccountLoggedIn, setIsAccountLoggedIn] = useState(false);

    // cookies state
    const [cookies, setCookie, removeCookie] = useCookies(['jwt']);

    const showNotification = (status) => {
        alert(status);
    }

    ////////////////////////////////////
    //       Web3 Functionality       //
    ////////////////////////////////////
    const checkIfWalletIsConnected = async () => {
        try {
            if (!ethereum) return alert("Please install MetaMask") // check if MetaMask is installed

            try {
                await ethereum.on('accountsChanged', function (accounts) {
                    setIsAccountLoggedIn(false);
                    removeCookie('jwt');
                    window.location.reload();
                })
            } catch (err) {
                console.log("Error in chaning account:", err)
            }

            try {
                await ethereum.on('disconnect', function (accounts) {
                    console.log("No account connected!")
                });
            } catch (err) {
                console.log("Error in account disconnect:", err);
            }
            
            await ethereum.request({ method: 'eth_accounts' }).then(async (accounts) => {
                if (accounts.length) {
                    await web3Checks(accounts[0]);
                    await connectToWs();
                    checkIfUserRegistered(accounts[0]);
                } else {
                    await connectWallet();
                }
            }).catch(err => {
                console.log("Error in requesting account:", err)
                showNotification("Please connect MetaMask!")
            });

        } catch (error) {
            console.log(error);
            showNotification('Please Install MetaMask!');
        }
    }

    const connectWallet = async () => {
        try {
            await ethereum.request({ method: 'eth_requestAccounts' }).then(async accounts => {
                if (accounts) {
                    await web3Checks(accounts[0]);
                    window.location.reload();
                }
            }).catch(err => {
                if (err.message.includes("lready")) {
                    showNotification("Please open and accept connect request in MetaMask!")
                } else {
                    showNotification("Please connect MetaMask!")
                }
                console.log(err.message)
            });

        } catch (error) {
            showNotification('Please install MetaMask!')
        }
        return currentAccount;
    }

    const web3Checks = async (account) => {
        if (!await checkLogin()) {
            await authenticate(account);
        }

        setCurrentAccount(account);
        checkUserNfts(account);
    }

    const authenticate = async (address) => {
        const loginUrl = authBackendBaseUrl + 'verify/';
        const reqNonceUrl = authBackendBaseUrl + 'reqNonce/';

        const req_params = { 'address': address };

        let tempToken;
        let temp_message;
        await axios.get(reqNonceUrl, { params: req_params })
            .then(resp => {
                tempToken = resp.data.tempToken;
                temp_message = resp.data.message;
            })
            .catch(err => console.log(err))

        // sign message
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        let signature = await signer.signMessage(temp_message, address);

        // verification form data
        var verificationData = new FormData();
        verificationData.append("signature", signature);
        verificationData.append("original_message", temp_message);
        verificationData.append("tempToken", tempToken);

        await axios({
            method: "post",
            url: loginUrl,
            data: verificationData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
            resp => {
                setCookie('jwt', resp.data.jwt, { 'maxAge': resp.data.exp });
                setIsAccountLoggedIn(true);
            }
        )
        .catch(err => {
            setIsAccountLoggedIn(false);
            alert(err.data.msg);
        })
    }

    const checkLogin = async () => {
        let jwt = cookies.jwt;
        if (jwt) {
            setIsAccountLoggedIn(true);
            return true;
        } else {
            setIsAccountLoggedIn(false);
            return false;
        }
    }

    ////////////////////////////////////
    //         Contract Calls         //
    ////////////////////////////////////
    const targetNetworkId = '0x1';

    const checkNetwork = async () => {
        if (window.ethereum) {
            const currentChainId = await window.ethereum.request({
                method: 'eth_chainId',
            });

            if (currentChainId !== targetNetworkId) {
                await switchNetwork();
            } else if ((currentChainId === targetNetworkId)) {
                return true;
            } else {
                return false;
            }
        }
    };

    const switchNetwork = async () => {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: targetNetworkId }],
        });

        window.location.reload();
    };

    const checkUserNfts = async (account) => {
        const targetNetwork = await checkNetwork();
        if (targetNetwork) {
            const contracts = getbuddhaBrothersContract();
            
            const accountNfts = await contracts.buddhabrothers.balanceOf(account);
            setAccountNfts(parseInt(accountNfts));
            
            const {reward, toSubstract} = await contracts.token.claimables(account);
            
            const baseURL = "https://deep-index.moralis.io/api/v2/" + account + "/nft/" + buddhabrothersAddress + "?chain=eth&format=decimal";
            
            const nfts = await fetch(baseURL, {
            				method: 'GET',
            				headers: {
            						Accept: "application/json",
            						"X-Api-Key": moralisApiKey
            					}
            				})
            				.then((res) => res.json())
            				.then((res) => {
            					return res.result;
            					})
            					.catch((e) => {
            						console.error(e);
            						console.error("Could not talk to OpenSea");
            						return null;
            					});
            
            let tokensIdArr: number[] = [];
            for (const nft of nfts) {
            	tokensIdArr.push(Math.floor(Number(nft.token_id)));
            }
            
            const claimable = await contracts.temple.getTotalClaimable(tokensIdArr);
           
            setTokenBalance(reward - toSubstract + claimable);
            
        } else {
            setAccountNfts(0);
            setTokenBalance(0);
        }        
    }

    ////////////////////////////////////
    //      Sockets/Backend State     //
    ////////////////////////////////////
    // local state
    const [wsState, setWs] = useState('');
    const [accountRegistered, setAccountRegistered] = useState(false);

    // event countdown state
    const [eventDetails, setEventDetails] = useState({
        event_id: '',
        event_start: '',
        started: false,
        completed: false
    });

    // lobby states
    const [lobbyJoined, setLobbyJoined] = useState(false);
    const [lobbyDetails, setLobbyDetails] = useState({
        joiningPeriod: false,
        lobbyTimer: 0,
        totalPlayers: 0
    });

    // game state
    const [gameState, setGameState] = useState({
        remaining_accounts: [],
        decisionMade: false,
        decision: 'split',
        losers: [],
        winner: "",
        total_rounds: 0,
        accounts_gains: {},
        account_gains: 0,
        round_started: false,
        running_pot: 0,
        jackpot: 0,
        round_accounts: {},
        round_start_time: 0,
        game_ended: false
    });

    ////////////////////////////////////
    //  Socket/Backend Functionality  //
    ////////////////////////////////////
    // get event details
    const checkIfUserRegistered = async (address) => {
        const checkUrl = backendBaseUrl + 'checkisregistered/';

        axios.get(checkUrl, { params: { "address": address } })
            .then(resp => { setAccountRegistered(true) })
            .catch(err => { setAccountRegistered(false) })
    }

    // Web socket connection and methods
    const connectToWs = async () => {
        var ws = new WebSocket(wsBackendBaseUrl);
        setWs(ws);

        ws.onopen = function () {
            console.log("WS Connected!");

            // request event details
            ws.send(JSON.stringify({
                'type': 'req_event_details'
            }));
        };

        ws.onmessage = async function (e) {
            var received_msg = JSON.parse(e.data);
            var msgObjKeys = Object.keys(received_msg);
            // console.log(received_msg)

            // get event details
            if (received_msg['type'] === 'event_update') {
                await updateEventAndGameState(received_msg);
            }

            if (received_msg['type'] === 'game_start') {
                await updateEventAndGameState(received_msg);
            }

            if (received_msg['type'] === 'round_start') { }

            if (received_msg['type'] === 'round_end') { }

            if (received_msg['type'] === 'game_end') { }

            
        };

        ws.onclose = function(e) {
            setTimeout(function() {
                connectToWs();
            }, 2000);
        };
        
        ws.onerror = function(err) {
        ws.close();
        };
    }

    const updateEventAndGameState = (received_msg) => {
        if (eventDetails.event_id === '') {
            setEventDetails({
                event_id: received_msg['event_id'],
                event_start: received_msg['event_start'],
                started: received_msg['started'],
                completed: received_msg['completed'],
                pregame_time: received_msg['pregame_time']
            })
        };

        setLobbyDetails({
            joiningPeriod: received_msg['pregame'],
            lobbyTimer: received_msg['pregame_time'],
            totalPlayers: received_msg['players_in_lobby']
        });


        var game_data = received_msg['game_state'];
        
        if (game_data) {
            // console.log("GAME DATA:", game_data)
            setGameState({
                ...gameState,
                remaining_accounts: game_data['remaining_accounts'],
                losers: game_data['losers'],
                winner: game_data['winner'],
                total_rounds: game_data['total_rounds'],
                accounts_gains: game_data['accounts_gains'],
                account_gains: game_data['accounts_gains'],
                round_started: game_data['round_started'],
                running_pot: game_data['running_pot'],
                jackpot: game_data['jackpot'],
                round_accounts: game_data['round_accounts'],
                round_start_time: game_data['round_start_time'],
                game_ended: game_data['game_ended']
            });

        } else {
            setGameState({
                ...gameState,
                remaining_accounts: received_msg['remaining_accounts']
            });
        }
    }

    return (
        <GameContext.Provider value={{
            ////////////////////////////////////
            //           WEB3 CONTEXT         //
            ////////////////////////////////////
            // general context
            connectWallet,
            currentAccount,
            showNotification,

            accountNfts,
            tokenBalance,

            // login context
            authenticate,
            isAccountLoggedIn,
            setIsAccountLoggedIn,

            ////////////////////////////////////
            //            WS CONTEXT          //
            ////////////////////////////////////
            wsState,

            // event states
            eventDetails,

            // lobby states and functions
            lobbyJoined,
            lobbyDetails,
            setLobbyDetails,
            setLobbyJoined,

            // event functions
            setEventDetails,

            accountRegistered,
            setAccountRegistered,

            cookies,

            // game state
            gameState,
            setGameState,
        }}>
            {children}
        </GameContext.Provider>
    )
}
export const GameContext = React.createContext();
