import React, { useContext } from "react";
import { GameContext } from "../contexts/GameContext";
import '../css/navbar.css';

const Navbar = () => {
    const {
        // general context
        connectWallet,
        currentAccount
    } = useContext(GameContext);

    return (
        <div className='navbar'>
            {!currentAccount ? 
                (<button className="connectWalletBtn" onClick={connectWallet}>Connect Wallet</button>)
                :
                (<span className="accountConnected">Connected: {currentAccount}</span>)
            }
        </div>
    );
}

export default Navbar;