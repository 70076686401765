import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NotFound } from 'http-errors';
import Main from './components/Main';
import Navbar from './components/NavBar';

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Main />} />
                <Route exact path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
