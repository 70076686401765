import { useEffect, useState } from "react";

const Timer = ({ time, type }) => {
    const [eventTimer, setEventTimer] = useState(':');
    let timerIntervalId;

    // countdown timer
    var timeUpdated = false;
    var seconds = parseInt(time+1);
    const startEventCountdown = () => {
        if (type !== 'simple') {
            const currentUnixTimeStamp = Math.round(new Date() / 1000);

            if (!timeUpdated) {
                if (seconds < currentUnixTimeStamp) {
                    seconds = 0;
                } else {
                    seconds = seconds - currentUnixTimeStamp;
                }
                timeUpdated = true;
            }
        }
        
        var days = Math.floor(seconds / 24 / 60 / 60);
        var hoursLeft = Math.floor((seconds) - (days * 86400));
        var hours = Math.floor(hoursLeft / 3600);
        var minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
        var minutes = Math.floor(minutesLeft / 60);
        var remainingSeconds = seconds % 60;
        function pad(n) {
            return (n < 10 ? "0" + n : n);
        }

        var timeLeft = "";

        if (days) {
            timeLeft += pad(days) + ":"
        }

        if (hours) {
            timeLeft += pad(hours) + ":";
        }

        if (minutes) {
            timeLeft += pad(minutes) + ":";
        }

        if (seconds) {
            timeLeft += pad(remainingSeconds);
        }

        setEventTimer(timeLeft);
        if (seconds === 0) {
            setEventTimer("Starting...");
            clearInterval(timerIntervalId)
        } else {
            seconds--;
        }
    }

    useEffect(() => {
        if (!timerIntervalId) {
            timerIntervalId = setInterval(startEventCountdown, 1000);
        }
    }, [])

    return (
        <span className="eventTimer">{eventTimer}</span>
    );
}

export default Timer;