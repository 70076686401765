import React, { useContext } from "react";
import { GameContext } from "../contexts/GameContext";
import '../css/main.css';
import Game from "./Game";
import EventRegistration from "./EventRegistration";
import { CONDITION_TO_PLAY } from "../utils/constants.js"


const Main = () => {
    const {
        // general context
        currentAccount,
        accountNfts,
        tokenBalance,

        // login context
        authenticate,
        isAccountLoggedIn,

        // event context
        eventDetails
    } = useContext(GameContext);

    return (
        <div className="mainContainer">
            <div className="backgroundImageContainer">
                <video autoPlay muted="muted" loop preload="auto" playsInline className="backgroundImage" type="video/mp4" src="./videos/buddhaVerseBackground.mp4" alt="" />
            </div>

            {
                // check if user's wallet is connected
                currentAccount ?
                    // check if account is logged in
                    isAccountLoggedIn ?
                        // check if user owns buddhabrothers nft(s)
                        tokenBalance > 0 || !CONDITION_TO_PLAY ?
                            // check if event exists and is not completed
                            eventDetails.event_id !== '' && eventDetails.completed !== true ?
                                // check if user is registered for the event
                                eventDetails.completed === false ?
                                    <Game />
                                    :
                                    <EventRegistration />
                                :
                                <div className="infoMsg">
                                    <span>No active Event</span>
                                </div>
                            :
                            <div className="infoMsg">
                                <span>You do not own any Belly Token.</span>
                            </div>
                        :
                        authenticate(currentAccount)
                    :
                    <div className="infoMsg">
                        <span>Please connect wallet to continue!</span>
                    </div>
            }
        </div>
    );
}

export default Main;
